.form-control{
    margin: 4px;
    border-radius: 40px;
    
  }

  .form-input-text{
      border-radius: 40px;
  }

  .alert-success{
      color: var(--green);
  }

  .alert-danger{
    color: var(--red);
}