@import url('https://fonts.googleapis.com/css?family=Josefin+Sans');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,200&display=swap');

:root
{
  --blue:  #509ae9; /*#3c73cc*/
  --blue2: #0c44ee; 
  --blue3: rgb(189, 231, 231);
  --white: #fff;
  --grey: #f5f5f5;
  --black1: #222;
  --black2: #999;
  --read: rgb(230, 132, 76);
  --black3: rgb(129, 125, 125);
  --blue-gradient: linear-gradient(#0dccea, #3c73cc);
  --search-box-color: #0dccea;
  --table-tr-boarder:rgb(102, 99, 99);
  --green:rgb(4, 121, 56);
  --red:rgb(228, 13, 13);
  --antiquewhite:antiquewhite;
   
}

.navbar {
  position:initial;
  background-image: linear-gradient(#727576, #727576);
  width: 100%;
  height: 70px;
  display: flex;
   
  align-items: center;
  border-bottom: 15px solid var(--white);
}

.navbar .topbar{
  background-color: transparent;
  width: 100%;
  padding-top: 1px;
   
    
}

.navbar  .title{
   
  font-size: 1.7em;
  color: var(--white);
  font-weight: bold;
  float: right;
  margin-top: 1px;

}

.navbar .profile{
   
  
  float: right;
  padding-bottom: 5px;
  margin-right: 10px;
  border-radius: 40px;

}

.navbar .profile .img{

  margin-right: 1px;
  height: 30px;
  width: 30px;
  
  float:right;
  font-size: 1.5em;
   
  text-align: center;
  padding-bottom: 10px;
}
.img1{

  float: right;
  padding-bottom: 2px;
  margin-right: 10px;
  border-radius: 40px;

  
  height: 30px;
  width: 30px;
  border-radius: 50px;
  background-color: var(--antiquewhite);
  font-size: 1.5em;
   
  text-align: center;
  
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none; /*toggle button background*/
}

.nav-menu {
  
  background-image: linear-gradient(#727576, #727576);
  width: 200px;
  height:100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
  overflow-y: scroll;
  margin-top: 10px;
  
}

.nav-menu.active {
  left: 0;
  transition: 950ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0px 8px 16px;
  list-style: none;
  height: 60px;
  
}

.nav-text a {
  text-decoration: none;
  color: var(--blue2);
  font-size: 15px;
  width: 90%;
  height: 90%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 2px;
  background-color: var(--white);
}

.nav-text a:hover {
  background-color: rgb(241, 220, 243);
  color: var(--read);
  border-radius: 50px;
}

 
.nav-menu-items {
  width: 100%;
}

.navbar-toggle {
  
  background:transparent;
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.nav-span {
  margin-left: 0px;
}

 

 .nav-title{
  position:absolute;
  width: 90%;
  float: right;
  max-height: 40px;
  padding: 1px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 30px;
  margin-top: 0px;
 }

/*========================NAV ICON===========================*/

.nav-icon
 {
   position: relative;
   display: block;
   min-width: 60px;
   height: 40px;
   line-height: 60px;
   text-align: center;
 }

 .nav-icon-img{

  font-size: 1.0em;
  color: white;
  background-color: white;

 }

  /*=======================logo================================*/

 .page-icon {
  width:70%;
  background: var(--white);
  object-fit: cover;
  border-radius: 60%;
}

.title-icon {
  width:70px;
  background: var(--white);
  object-fit: cover;
  border-radius: 60%;
}

/*========================SCROLL BAR=========================*/

/* width */
::-webkit-scrollbar {
  width: 7px;
  color: var(--white);
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px var(--antiquewhite);
  border-radius: 1px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(161, 154, 154);
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(216, 211, 211);
}


/*======================SEARCH========================*/
.search{
  position: relative;
  width: 400px;
  margin: 0 10px;
}

.search label 
{
  position: relative;
  width: 100%;
}

.search label input
{
 
  width: 100%;
  height: 20px;
  border-radius: 40px;
  padding: 5px 20px;
  padding-left: 35px;
  font-size: 18px;
  outline: none;
  border: 1px solid var(--black2);
}

.search-icon{

 position: absolute;
 top: 0;
 left: 10px;
 font-size: 1.2em;

}

.user{
  position:absolute;
  min-width: 40px;
  height: 50px;
  border-radius: 50%;
  overflow:hidden;
  cursor: pointer;
  margin-right: 2px;
  text-align:flex-end;

}

.user img{
   
  top:0;
  left:0;
  width: 40px;
  height: 50px;
  object-fit: cover;
}

.navbar-left{
  background-color: transparent;
  color: #fff;
  margin-left: 0px;
  padding-bottom: 60px;
}

.navbar-left .row-menu {
 
  color: white;
  background-image: linear-gradient(#727576, #727576);
  margin: 4px;
  padding-left: 20px;
  font-family: 'Josefin Sans';
  font-size: 16px;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-color: aliceblue;
  border-bottom:solid 1px;
  

}

.navbar-left .row-menu:hover{

  color: orange;
  background-image: linear-gradient(#fff, #fff);
  margin: 4px;
  padding-left: 20px;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: bold;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-color: rgb(241, 211, 10);
  border-bottom:solid 1px;
  cursor: pointer;
  
}

.navbar-left .section-title{
  color: rgb(209, 202, 202);
  text-align: center;
}

