 
:root
{
  --blue: #3c73cc;
  --blue2: #a3c1f1;
  --white: #fff;
  --grey: #f5f5f5;
  --black1: #222;
  --black2: #999;
  --black3: rgb(129, 125, 125);
  --orange: #db7515
   
}



/* printing */

@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {
    margin-top: 0rem;
    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 0mm;
}

.printing-page{
  width: 100%;
  height: 100%;
  background-color:white;
}

.main{
  background-color: #3c73cc;
}
